import { Spinner } from '@acpaas-ui/react-components';
import { AUTH_ACTION_RESET } from 'actions/types';
import { SportStadHeader } from 'components';
import MetaTags from 'components/MetaTags';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { clearToken, getToken } from 'util/localStorage';
import { getQueryParams } from 'util/queryParams';

function Logout() {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = getQueryParams(window.location.search || '');

  useEffect(() => {
    const token = getToken();
    if (token) {
      clearToken();
      dispatch({ type: AUTH_ACTION_RESET });
      history.push('/jeugd');
    }
  }, [dispatch, history, params, params.fromUrl]);

  return (
    <>
      <MetaTags />
      <SportStadHeader title={'Aanmelden'} backgroundImage={''} />
      <div className="main u-wrapper">
        <div className="u-container u-margin-top-lg u-margin-bottom-lg u-text-center" role="main">
          <Spinner size="large" className="u-margin-bottom-lg" />
          <h3>Afmelden...</h3>
        </div>
      </div>
    </>
  );
}

export default Logout;
